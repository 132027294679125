.emptyMessageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}
.emptyImageContainer {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 43px;
}
.emptyImage {
  width: 70.13px;
}
.emptyMessageTitle {
  font-family: "Roboto Condensed", serif;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  line-height: 160%;
}
.emptyMessageTitle span {
  font-family: "Roboto Condensed", serif;
}
@media (max-width: 575px) {
  .emptyMessageContainer {
    margin-top: 90px;
  }
  .emptyImageContainer {
    width: 170px;
    height: 170px;
  }
  .emptyImage {
    width: 60px;
  }
  .emptyMessageTitle {
    font-size: 20px;
  }
}
