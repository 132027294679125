h1,
h2,
h3,
h4 {
  font-family: "Roboto Condensed", serif;
  color: var(--textColor) !important;
}
h5,
h6,
p,
button,
span,
li {
  font-family: "Roboto Condensed", serif;
  color: var(--textColor) !important;
}

body {
  --primaryColor: #0c0a3e;
  --accentColor: #eb5e28;
  --secondaryColor1: #0c6291;
  --secondaryColor2: #746d75;
  --secondaryColor3: #218380;
  --textColor: #000000;
}
