.countDownContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.messageText {
  text-transform: capitalize;
  font-size: 14px;
  color: var(--secondaryColor2) !important;
  margin-bottom: 0;
  font-weight: 500;
}
.countDownElement {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 48px;
  border-radius: 0;
  background: white;
  border: 1px solid var(--secondaryColor2);
}
.countDownText {
  font-size: 22px;
  margin: 0;
  font-weight: 500;
  letter-spacing: 4.5;
}
.countDownElementTag {
  font-size: 13px;
  text-transform: capitalize;
  color: var(--secondaryColor2) !important;
  text-align: center;
  margin: 12px 0 0;
}
.elementDivider {
  font-size: 2rem;
  margin: 0 5px;
}
@media (max-width: 1199px) {
  .countDownContainer {
    margin-bottom: 60px;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .countDownElement {
    width: 65px;
  }
}
@media (max-width: 767px) {
  .countDownContainer {
    margin-bottom: 70px;
  }
  .countDownText {
    font-size: 1.1rem;
  }
}
@media (max-width: 400px) {
  .countDownElement {
    width: 65px;
  }
}
