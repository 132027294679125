.footerContainer {
  background-color: var(--primaryColor);
  padding-top: 50px;
  border-top: 1px solid rgba(204, 197, 185, 0.5);
}
.footerBottomDivider {
  border-top: 1px solid rgba(204, 197, 185, 0.5);
  height: 1px;
  margin-top: 60px;
}
.businessLogo {
  width: 86px;
}
.footerBusinessName {
  font-size: 18px;
  text-transform: capitalize;
  margin: 20px 0 0;
}
.sectionTitleContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: start;
}
.footerSectionTitle {
  font-size: 20px;
  text-transform: capitalize;
  color: white !important;
  border-bottom: 2px solid var(--secondaryColor2);
  margin: 0;
}
.footerNavLinkContainerParent {
  margin: 0;
}
.navLink {
  text-decoration: none;
}
.navLinkContainer {
  display: flex;
  margin-bottom: 20px;
}
.footerNavLink {
  font-size: 15px;
  text-transform: capitalize;
  margin: 0;
  cursor: pointer !important;
  transition: all 0.3s;
  color: white !important;
}
.footerNavLink:hover {
  color: var(--accentColor) !important;
}
.contactInfo {
  text-transform: none;
  cursor: default;
}
.contactIcon {
  font-size: 18px;
  margin-right: 10px;
  color: var(--secondaryColor1) !important;
}
.copyrightText {
  font-size: 13px;
  margin-top: 20px;
  text-align: center;
  color: white !important;
}
.copyrightSymbol {
  color: white !important;
}
@media (max-width: 1199px) {
  .footerNavLinkContainerParent {
    margin-top: 50px !important;
  }
  .businessLogo {
    width: 95px;
  }
}
@media (max-width: 767px) {
  .footerNavLinkContainerParent {
    margin-top: 20px !important;
  }
  .footerBusinessName {
    font-size: 20px;
  }
  .footerBusinessName {
    margin: 30px 0 0;
  }
  .navLinkContainer {
    justify-content: center;
  }
  .footerSectionTitle {
    text-align: center;
  }
  .navLinkSectionContainer {
    margin-bottom: 50px;
  }
  .footerBottomDivider {
    margin: 0;
  }
  .footerNavLink {
    text-align: center;
  }
  .businessLogo {
    margin-bottom: 30px;
    width: 65px;
  }
  .sectionTitleContainer {
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .footerNavLinkContainerParent {
    margin-bottom: 0;
  }
}
