.singleEventContainer {
  margin-bottom: 50px;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 4px 19px 0 rgba(0, 0, 0, 0.25);
  background: white;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.eventDateContainer {
  width: 62px;
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--secondaryColor1);
}
.eventMonth,
.eventDay {
  font-size: 16px;
  color: white !important;
  margin: 0;
  font-weight: 500;
}
.eventThumbnailContainer {
  width: 100%;
  aspect-ratio: 1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 28px;
  transition: all 0.5s;
}
.eventDetailsContainer {
  padding: 0 17px;
}
.eventName {
  font-weight: 500;
  font-size: 18px;
  color: black;
  text-align: center;
  margin-bottom: 10px;
  text-transform: capitalize;
  line-height: 135%;
}
.eventLocation,
.eventPriceTag {
  font-size: 14px;
  color: var(--secondaryColor2) !important;
  text-align: center;
  margin-bottom: 10px;
}
.eventPriceTag {
  margin-bottom: 28px;
  color: var(--secondaryColor2) !important;
}
.bookNowButtonContainer {
  position: relative;
}
.bookNowButton {
  width: 100%;
}
.buttonHalfCircle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #d9d9d9;
  position: absolute;
  top: 15px;
  left: -14px;
}
.buttonDashedLine {
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 41px;
  border-width: 2px;
  border-style: dashed;
  border-color: white;
  /* border-color: transparent;
  border-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 10 10"><line x1="0" y1="5" x2="10" y2="5" stroke="white" stroke-width="3" stroke-dasharray="5, 2.5"/></svg>')
    1; */
}
@media (max-width: 575px) {
  .singleEventContainer {
    max-width: 380px;
  }
  .eventDateContainer {
    width: 42px;
    height: 65px;
  }
  .eventMonth,
  .eventDay {
    font-size: 13px;
  }
  .eventThumbnailContainer {
    margin-bottom: 18px;
  }
  .eventName {
    font-size: 17px;
  }
  .eventLocation,
  .eventPriceTag {
    font-size: 13px;
  }
  .bookNowButton {
    padding: 14px 44px !important;
    font-size: 17px !important;
  }
  .buttonDashedLine {
    left: 28px;
    border-width: 1px;
  }
  .buttonHalfCircle {
    left: -15px;
    top: 15px;
    width: 27px;
    height: 27px;
  }
}
