.businessLogo {
  width: 30px;
  cursor: pointer;
}
.eventgoozeName {
  font-size: 23px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  color: white !important;
  margin: 0 0 0 10px;
  position: relative;
  top: -3px;
  cursor: pointer;
}
@media (max-width: 575px) {
  .businessLogo {
    width: 25px;
  }
  .eventgoozeName {
    font-size: 16px;
    margin: 0 0 0 8px;
  }
}
