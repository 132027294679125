.topBarContainer {
  margin-bottom: 60px;
}
.topBannerUnderline {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--secondaryColor2);
  margin-top: 20px;
}
.leftContainer {
  width: 90%;
}
.eventDetailsContainer {
  padding: 70px 0 100px;
}
.backButtonIcon {
  font-size: 34px;
  margin-right: 10px;
  color: var(--accentColor) !important;
  cursor: pointer;
}
.eventTitle {
  font-weight: 600;
  color: var(--primaryColor) !important;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.changeOrderButton {
  margin: 0;
  width: auto;
  background: var(--primaryColor) !important;
  border: 1px solid gray !important;
  text-transform: capitalize;
  font-size: 13px !important;
  color: var(--secondaryColor2) !important;
  padding: 10px 26px !important;
}
.mobileChangeOrderButton {
  text-transform: capitalize;
  font-size: 13px !important;
  color: var(--secondaryColor2) !important;
  cursor: pointer;
  text-decoration: underline;
}
.changeOrderButton:hover {
  color: white !important;
  border-color: var(--accentColor) !important;
}
.subTitleContainer {
  margin-bottom: 50px;
}
.pageSubTitle {
  font-size: 20px;
  color: var(--primaryColor) !important;
  text-transform: capitalize;
  font-weight: 600;
}
.billingInstructions {
  font-size: 16px;
  line-height: 177%;
  margin-bottom: 50px;
}
.eventDetailsDivider {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed var(--secondaryColor2);
  margin-bottom: 15px;
}
.orderDetailsGridTitle {
  text-transform: capitalize;
  font-size: 14px;
  color: var(--secondaryColor2) !important;
  margin-bottom: 20px;
}
.eventName {
  font-size: 21px;
  font-weight: bold;
  color: var(--secondaryColor2) !important;
  text-transform: capitalize;
  margin: 0 0 15px;
}
.eventDataTitle {
  font-size: 17px;
  text-transform: capitalize;
  color: var(--textColor) !important;
  margin-bottom: 15px;
  line-height: 160%;
}
.packageItemTitle {
  color: var(--textColor) !important;
  font-size: 16px;
}
.promotionMessage {
  font-size: 12px;
  color: var(--secondaryColor2) !important;
  margin: 0;
}
.grandTotal {
  font-weight: 600;
}
.grandTotalDivider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--secondaryColor2);
  margin-bottom: 15px;
}
.paymentGatewayImageContainer {
  background: #2447d7;
}
.agreeTermsAndConditionsContainer {
  margin-top: 20px !important;
  margin-bottom: 20px;
}
.agreeTermsCheckbox {
  width: 20px;
  cursor: pointer;
  position: relative;
  top: 0;
}
.agreeTextContainer {
  padding-left: 0 !important;
}
.agreeText {
  font-size: 14px;
  color: var(--textColor) !important;
  margin: 0;
}
.payNowButton:disabled {
  text-transform: uppercase;
  font-weight: 500 !important;
}
.payNowButton {
  margin-top: 0;
  width: 100% !important;
  text-transform: uppercase;
  border-radius: 0 !important;
}
.termsAndConditionsLink {
  color: var(--secondaryColor1) !important;
}

@media (max-width: 1199px) {
  .eventDetailsContainer {
    padding: 50px 0 100px;
  }
}

@media (max-width: 991px) {
  .leftContainer {
    width: 95%;
  }
  .eventTitle {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .leftContainer {
    width: 100%;
    margin-bottom: 60px;
  }
  .topBarContainer {
    margin-bottom: 50px;
  }
  .eventTitle {
    text-align: center;
    font-size: 1.3rem;
  }
  .subTitleContainer {
    margin-bottom: 30px;
  }
  .billingInstructions {
    margin-bottom: 40px;
  }
}
