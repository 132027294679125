.componentDiv {
  margin-bottom: 20px;
}
.label {
  font-family: "Roboto Condensed", serif;
  font-size: 14px;
  line-height: 166.9%;
  margin-bottom: 5px;
  color: var(--secondaryColor2) !important;
}
.inputBox {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #b7b7b7;
  padding: 11px 13px;
  outline: none !important;
  color: var(--textColor);
  font-size: 14px;
}
input::placeholder {
  font-family: "Roboto Condensed", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 148.9%;
  color: var(--textColor);
}
.requiredSign {
  color: red !important;
  display: inline-block;
  margin-left: 2px;
}
.errorMessage {
  color: red !important;
  font-size: 13px;
  margin-left: 10px;
}
@media (max-width: 991px) {
  .errorMessage {
    margin: 0;
  }
}
