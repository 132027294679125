.completeOrderPageContainer {
  padding: 120px 0 150px;
}

.faildIcon {
  color: #721121 !important;
}
.successIcon {
  color: #218380 !important;
}
.icon {
  font-size: 70px;
  margin-bottom: 30px;
}
.message {
  font-weight: 400;
  font-size: 18px;
}
.orderId {
  margin-bottom: 30px;
  text-align: center;
  font-size: 19px;
}
.message {
  text-align: center;
  line-height: 1.7 !important;
}
@media (max-width: 575px) {
  .message {
    font-size: 16px;
  }
  .orderId {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
