.actionButton {
  background-color: var(--accentColor) !important;
  padding: 12px 44px !important;
  border-radius: 0 !important;
  color: white !important;
  outline: none !important;
  box-shadow: none !important;
  transition: all 0.3s ease-in-out !important;
  text-transform: capitalize;
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "Roboto Condensed", serif !important;
}
.actionButton:hover {
  background-color: #d45524 !important;
  border-color: #d45524 !important;
}
.actionButton:active {
  background-color: #d45524 !important;
  border-color: #d45524 !important;
  outline: none !important;
}

.actionButton:disabled {
  cursor: not-allowed !important;
  background-color: white !important;
  font-weight: 400 !important;
  border-color: var(--secondaryColor2) !important;
  color: var(--secondaryColor2) !important;
  opacity: 1 !important;
}

@media (max-width: 1199px) {
  .actionButton {
    font-size: 18px !important;
  }
}
