.topBarContainer {
  height: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.backButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  background: var(--accentColor);
  z-index: 2;
  cursor: pointer;
}
.backButtonIcon {
  color: black !important;
  font-size: 28px;
  cursor: pointer;
}
.pageTitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: bold;
}
.scanButtonContainerParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.scanIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--accentColor);
  cursor: pointer;
  margin-bottom: 20px;
}
.scanIcon {
  color: black !important;
  font-size: 45px;
  cursor: pointer;
}
.startScanningText {
  font-size: 16px;
  text-transform: capitalize;
  margin: 0;
}
