.pageContainer {
  padding: 70px 0;
}
.pageTitleContainer {
  margin-bottom: 60px;
}
.pageTitle {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--primaryColor) !important;
}
.pageTitleUnderline {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--secondaryColor2);
}
.subTitle {
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 2rem;
  color: white;
}
.siteName {
  color: var(--secondaryColor1) !important;
}
.content,
.list li {
  color: var(--secondaryColor2) !important;
}
.linkText {
  color: var(--secondaryColor1) !important;
}
@media (max-width: 767px) {
  .pageContainer {
    padding: 50px 0;
  }
}
@media (max-width: 575px) {
  .pageTitle {
    font-size: 24px;
  }
}
