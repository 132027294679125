.modalContainer > div > div {
  background: var(--secondaryColor2);
  border-radius: 3px;
}
.modalBody {
  padding: 50px 70px 60px !important;
  background: white !important;
  position: relative;
}
.modalCloseButton {
  position: absolute;
  top: 12px;
  right: 12px;
  color: var(--textColor) !important;
  font-size: 1.4rem;
  cursor: pointer;
}
.modalHeader {
  border: none;
}
.modalTitle {
  font-size: 1.3rem;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 50px;
  font-weight: 600;
  color: var(--primaryColor) !important;
}
.sendMessageButton {
  margin-top: 10px !important;
  width: 100% !important;
  text-transform: uppercase;
  border-radius: 0 !important;
}
@media (max-width: 575px) {
  .modalBody {
    padding: 40px 70px 60px !important;
  }
}
@media (max-width: 450px) {
  .modalBody {
    padding: 40px 50px 60px !important;
  }
}
@media (max-width: 400px) {
  .modalBody {
    padding: 40px 40px 60px !important;
  }
}
