.counterButton {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--accentColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.buttonContent {
  color: white !important;
  font-size: 14px;
  font-weight: bold;
}
.countContainer {
  margin: 0 10px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
