.promoButtonContainer {
  padding: 22px 10px;
  border-top: 1px solid var(--secondaryColor2);
  border-bottom: 1px solid var(--secondaryColor2);
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.havePromocodeText {
  margin: 0;
  text-transform: capitalize;
  text-align: center;
  font-weight: 500;
  color: var(--accentColor) !important;
  font-size: 17px;
  cursor: pointer;
}
