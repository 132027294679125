.AllEventsPageContainer {
  padding: 70px 0 100px;
  min-height: 90vh;
  background-color: #f9f9f9;
}
.sectionTitle {
  font-size: 26px;
  color: var(--primaryColor) !important;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .AllEventsPageContainer {
    padding: 50px 0 100px;
  }
}
@media (max-width: 575px) {
  .sectionTitle {
    font-size: 24px;
    text-align: center;
  }
}
