.sectionTitle {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
  color: var(--primaryColor) !important;
  line-height: 150%;
}
.decoratedSectionTitle {
  line-height: 180%;
}
@media (max-width: 991px) {
  .sectionTitle {
    font-size: 25px;
  }
}
@media (max-width: 575px) {
  .sectionTitle {
    font-size: 23px;
  }
}
/* styles of top banner section starts here */
.tobBannerSection {
  background-color: var(--primaryColor);
  padding: 60px 0 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.topBannerContentContainer {
  padding-right: 70px;
}

.topBannerTitle {
  color: white !important;
  font-size: 35px;
  margin-bottom: 30px;
}
.topBannerDescription {
  font-size: 19px;
  font-weight: 400;
  color: #c2c1c2 !important;
}
.topBannerImage {
  width: 150px;
}
@media (max-width: 1199px) {
  .topBannerImage {
    width: 130px;
  }
  .topBannerTitle {
    font-size: 33px;
  }
  .topBannerDescription {
    font-size: 17px;
  }
}
@media (max-width: 991px) {
  .topBannerTitle {
    font-size: 29px;
  }
  .topBannerDescription {
    font-size: 16px;
  }
  .topBannerContentContainer {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .topBannerImage {
    margin-bottom: 30px;
    width: 100px;
  }
  .topBannerTitle {
    font-size: 23px;
  }
  .topBannerDescription {
    font-size: 16px;
  }
  .topBannerActionButtonContainer {
    display: flex;
    justify-content: center;
  }
  .topBannerContentContainer {
    padding-right: 0;
  }
  .tobBannerSection {
    padding: 30px 0;
  }
}
@media (max-width: 575px) {
  .topBannerTitle {
    font-size: 21px;
    text-align: center;
  }
  .topBannerDescription {
    display: none;
  }
}
/* styles of top banner section ends here */
/* styles of latest events section starts here */
.latestEventsSection {
  padding: 74px 0 90px;
  background-color: #f9f9f9;
}
.latestEventsSectionTitle {
  margin-bottom: 50px;
}
.viewAllEventsTextButton {
  padding: 5px 15px;
  border-bottom: 3px solid var(--accentColor);
  margin-top: 30px;
  cursor: pointer;
}
.viewAllEventsTextButton p {
  font-size: 18px;
  margin: 0;
  color: var(--primaryColor) !important;
}
@media (min-width: 575px) {
  .latestEventsSectionTitle {
    text-align: start;
  }
}
@media (max-width: 767px) {
  .latestEventsSection {
    padding: 50px 0 70px;
  }
}
@media (max-width: 575px) {
  .latestEventsSection {
    padding: 40px 0 70px;
  }
  .viewAllEventsTextButton {
    margin-top: 20px;
  }
}
/* styles of latest events section ends here */
/* styles of client testimonial section starts here */
.clientTestimonialSection,
.messageFromFounderSection {
  /* background-color: var(--secondaryColor2); */
  padding: 70px 0 90px;
}
.testimonialSectionTitle {
  margin-bottom: 110px;
}
.clientImageContainer {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 290px;
  height: 100%;
  min-height: 420px;
  padding: 0 27px 22px;
  display: flex;
  align-items: end;
}
.clientDetailsContainer {
  padding: 20px;
  background-color: var(--primaryColor);
  width: 100%;
}
.clientName,
.founderName {
  font-size: 20px;
  color: white !important;
  font-weight: 600;
  margin-bottom: 17px;
  text-align: center;
}
.clientSocialLinksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clientSocialIconContainer {
  padding: 0 7.5px;
}
.clientReviewContent {
  font-size: 17px;
  line-height: 200%;
  margin: 23px 0;
}
.clientReviewContentHighlitedContent {
  color: var(--primaryColor);
  font-weight: 500;
}
.quoteSymbol {
  width: 40px;
}
.closingQuoteSymbol {
  rotate: 180deg;
}
@media (max-width: 991px) {
  .clientTestimonialSection,
  .messageFromFounderSection {
    padding: 60px 0 90px;
  }
  .testimonialSectionTitle {
    margin-bottom: 70px;
  }
  .clientImageContainer {
    width: 260px;
    min-height: unset;
    height: 330px;
    margin-bottom: 40px;
  }
  .clientName,
  .founderName {
    font-size: 19px;
    margin-bottom: 13px;
  }
  .founderName {
    font-size: 16px;
  }
  .clientReviewContent {
    font-size: 17px;
  }
}
@media (max-width: 575px) {
  .clientTestimonialSection,
  .messageFromFounderSection {
    padding: 60px 0;
  }
  .quoteSymbol {
    width: 30px;
  }
  .clientReviewContent {
    font-size: 16px;
    margin: 7px 0;
  }
  .clientReviewContentContainer {
    padding: 0 !important;
  }
}
/* styles of client testimonial section ends here */
.messageFromFounderSection {
  background: var(--primaryColor);
}
.founderSectionTitle {
  color: white !important;
}
.founderMessage {
  color: white !important;
}
