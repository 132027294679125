.ticketDetailsContainer {
  margin-bottom: 20px;
}
.packageTitleContainer {
  margin-bottom: 11px;
}
.packageTitle {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  line-height: 146%;
}
.availableSeats {
  font-size: 13px;
  text-transform: capitalize;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: gray !important;
}
.packageSubTitle {
  text-transform: capitalize;
  font-size: 13px;
  color: var(--secondaryColor2) !important;
  margin-bottom: 4px;
}
.ticketPrice {
  font-size: 19px;
  margin: 0;
}
.packageDetailsDivider {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  border-top: 1px dashed var(--secondaryColor2);
}
.packageNotAvailableText {
  background: var(--secondaryColor2);
  color: white !important;
  text-align: center;
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.comingSoonText {
  background: var(--secondaryColor1);
}
@media (max-width: 575px) {
  .packageNotAvailableText {
    font-size: 13px;
  }
}
