.customContainer {
  max-width: 400px !important;
  background: var(--primaryColor);
  padding: 0 12px 20px !important;
}
.ticketDetailsContainer {
  padding: 30px 40px;
  margin-bottom: 25px;
}
@media (max-width: 400px) {
  .ticketDetailsContainer {
    padding: 30px 20px;
  }
}
.validSection {
  background-color: var(--secondaryColor3);
}
.invalidSection {
  background-color: #a50104;
}
.alreadyCheckedInSection {
  background-color: #e89116;
}
.eventName {
  text-align: center;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: bold;
  color: white !important;
}
.ticketStatusContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}
.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.validIcon,
.closeIcon {
  font-size: 60px;
  font-weight: 200;
  margin-bottom: 10px;
  color: white !important;
}
.ticketStatusText {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
  color: white !important;
}
.ticketDetailsItemContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  border-bottom: 1px dashed white;
}
.ticketDetailsItemContainer p {
  color: white !important;
}
.ticketDetailsItemTitle {
  font-size: 16px;
  text-transform: capitalize;
}
.checkInButton:disabled {
  text-transform: uppercase;
  font-weight: 500 !important;
  background-color: var(--primaryColor) !important;
  cursor: not-allowed;
  color: var(--secondaryColor2) !important;
}
.checkInButton {
  width: 100% !important;
  text-transform: uppercase;
  border-radius: 0 !important;
}
.bottomScannerParent {
  height: 90px;
  width: 100%;
  background: var(--primaryColor);
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}
.scanIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--accentColor);
  cursor: pointer;
}
.scanIcon {
  color: black !important;
  font-size: 27px;
  cursor: pointer;
}
