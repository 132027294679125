.scannerPage {
  overflow: hidden;
}
.customContainer {
  max-width: 400px !important;
}
.loginFormContainer {
  height: 100vh;
  display: flex;
}
.loginTitle {
  text-align: center;
  font-size: 25px;
  margin-bottom: 30px;
  font-weight: bold;
}
.loginButton:disabled {
  text-transform: uppercase;
  font-weight: 500 !important;
}
.loginButton {
  margin-top: 20px;
  width: 100% !important;
  text-transform: uppercase;
  border-radius: 0 !important;
}
