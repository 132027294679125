.eventDetailsDivider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--secondaryColor2);
  margin-bottom: 19px;
}

@media (max-width: 1199px) {
  .ticketTimeVenueContainer {
    margin: 50px 0;
  }
}
@media (max-width: 767px) {
  .ticketTimeVenueContainer {
    margin: 60px 0;
  }
}
@media (max-width: 575px) {
  .ticketTimeVenueContainer {
    margin-bottom: 50px;
  }
}
