.leftContainer {
  width: 90%;
}
.eventDetailsContainer {
  padding: 70px 0 100px;
}
.eventTitle {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 26px;
  margin-bottom: 60px;
  line-height: 140%;
  color: var(--primaryColor) !important;
}
.eventBannerImage {
  width: 300px;
  border: 1px solid #38383812;
  margin-right: 15px;
}
.eventDescription {
  margin-top: 50px;
  line-height: 160%;
}
.eventDescription p,
.eventDescription li {
  letter-spacing: 0.4px;
}

.eventDescription h1,
.eventDescription h2,
.eventDescription h3,
.eventDescription h4,
.eventDescription h5,
.eventDescription h6 {
  font-family: "Roboto Condensed", serif;
  font-size: 19px;
  font-weight: 600;
  margin: 20px 0 12px;
  line-height: 146%;
}
.eventDescription h1,
.eventDescription h2,
.eventDescription h3 {
  color: var(--secondaryColor1) !important;
  font-size: 20px;
}

.eventDetailsDivider {
  width: 100%;
  height: 1px;
  border: 1px solid #38383812;
  margin-bottom: 20px;
}
.rightSideBarSubTitle {
  margin-bottom: 31px;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--primaryColor) !important;
}
.ticketTotal {
  font-size: 19px;
  font-weight: 500;
  margin: 0;
  color: black;
}
.pricingTotalDivider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--secondaryColor2);
  margin: 20px 0;
}
.BuyTicketsButton:disabled {
  text-transform: uppercase;
  font-weight: 500 !important;
}
.BuyTicketsButton {
  width: 100% !important;
  text-transform: uppercase;
  border-radius: 0 !important;
}
.readMoreText {
  color: var(--accentColor) !important;
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  display: inline-block;
  border: 1px solid var(--accentColor);
  padding: 1px 15px;
}
@media (max-width: 1199px) {
  .leftContainer {
    width: 75%;
  }
  .eventDetailsContainer {
    padding: 50px 0 100px;
  }
  .eventTitle {
    font-size: 26px;
    margin-bottom: 50px;
  }
  .eventBannerImage {
    width: 100%;
    margin: 0;
  }
  .ticketTimeVenueContainer {
    margin-bottom: 60px;
  }
}
@media (max-width: 991px) {
  .leftContainer {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .leftContainer {
    width: 100%;
  }
  .eventTitle {
    font-size: 1.8rem;
    text-align: center;
  }
  .eventDescription {
    margin: 30px 0 80px;
  }
  .ticketTimeVenueContainer {
    margin-bottom: 60px;
  }
  .rightSideBarSubTitle {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
  }
}
@media (max-width: 575px) {
  .eventTitle {
    font-size: 1.5rem;
    margin-bottom: 50px;
    font-weight: 600;
  }
  .eventDescription {
    margin-bottom: 70px;
  }
  .ticketTimeVenueContainer {
    margin-bottom: 50px;
  }
}
