.ticketMetaDataContainer {
  display: flex;
  margin-bottom: 19px;
  align-items: center;
}
.iconContainer {
  display: flex;
}
.iconContainer span {
  margin-right: 10px;
  color: var(--secondaryColor1) !important;
  font-size: 21px;
}
.eventMetaDataLabel {
  font-weight: 500;
  margin-right: 11px;
}
.eventMetaData {
  font-size: 16px;
  margin: 0;
  text-transform: capitalize;
}
