.ticketDetailsContainer {
  margin-bottom: 20px;
}
.packageTitleContainer {
  margin-bottom: 11px;
}
.packageTitle {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0;
  line-height: 146%;
  color: white !important;
}
.availableSeats {
  font-size: 13px;
  text-transform: capitalize;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: #b7b3b3 !important;
}
.packageDetailsDivider {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  border-top: 1px dashed var(--secondaryColor2);
}
.ticketCountStyle {
  color: white !important;
}
