.phoneNumberInputContainer {
  margin-bottom: 15px;
}
.phoneNumberContainer {
  box-sizing: border-box;
  width: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  outline: none !important;
  color: var(--textColor);
  font-size: 14px;
}
.phoneNumberContainer input {
  border: none;
  outline: none;
  color: var(--textColor) !important;
  font-size: 14px;
}
.phoneNumberContainer fieldset {
  border-color: #b7b7b7 !important;
}
.hiddenFormInput {
  margin: 0;
}
