.navLinkText {
  text-transform: capitalize;
  cursor: pointer;
  color: white !important;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  transition: all 0.3s;
}
.navLinkText:hover {
  color: var(--accentColor) !important;
}
.activeTab p {
  color: var(--accentColor) !important;
}
.navLinkContainer {
  display: flex;
  align-items: center;
}
.accountIconContainer {
  margin-left: 48px !important;
  padding: 0 !important;
}
.accountIcon {
  color: white !important;
  font-size: 30px;
}
.menuItem {
  padding: 6px 20px !important;
  font-family: "Roboto Condensed", serif !important;
  font-weight: 500;
  color: var(--primaryColor) !important;
}
.menuDivider {
  margin: 8px 0 !important;
  border-color: var(--secondaryColor2) !important;
}
.comingSoonTag {
  background-color: var(--secondaryColor1);
  color: white !important;
  margin-left: 20px;
  font-size: 13px;
  font-family: "Roboto Condensed", serif !important;
  font-weight: 500;
  padding: 2px 8px;
}
@media (max-width: 767px) {
  .navLinkText {
    font-size: 14px;
  }
  .accountIconContainer {
    margin-left: 16px !important;
  }
}
